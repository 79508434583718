<template>
  <div id="wrapper">
    <Nav class="navbar is-dark">
      <div class="navbar-brand">
        <router-link to="/" class="navbar-item"><strong>Contract Solution</strong></router-link>
          <div class="navbar-burger" data-target="navbarExampleTransparentExample">
            <span></span>
            <span></span>
            <span></span>
        </div>
      </div>

      <div id="navbarExampleTransparentExample" class="navbar-menu">
        <div class="navbar-start">
 
        </div>
        <div class="navbar-end">
          <template v-if="$store.state.isAuthenticated">
            <router-link to="/dashboard" class="navbar-item">Dashboard</router-link>
            <router-link to="/dashboard/clients" class="navbar-item">Suppliers</router-link>
            <router-link to="/dashboard/buyers" class="navbar-item">Buyers</router-link>
            <router-link to="/dashboard/invoices" class="navbar-item">Invoices</router-link>
            <router-link to="/dashboard/reports" class="navbar-item">Reports</router-link>



            <div class="navbar-item">
              <div class="buttons">
                <router-link to="/dashboard/invoices/add" class="button is-success ">Add Incoivce</router-link>
                <router-link to="/dashboard/my-account" class="button is-light">My account</router-link>

              </div>
            </div>
          </template>

          <template v-else>
            <router-link to="/" class="navbar-item">Home</router-link>

            <div class="navbar-item">
              <div class="buttons">
                <router-link to="/sign-up" class="button is-success"><strong>Sign up</strong></router-link>
                <router-link to="/log-in" class="button is-light">Log In</router-link>
              </div>
            </div>
          </template>
        </div>
      </div>
    </Nav>

  <section class="section">
    <RouterView/>
  </section>

  <footer class="footer">
    <p class="has-text-centered">Copyright © 2021</p>
  </footer>
</div>
</template>


<script>
  import axios from 'axios'

  export default {
    name: 'App',
    beforeCreate() {
      this.$store.commit('initializeStore')

      const token = this.$store.state.token

      if (token) {
        axios.defaults.headers.common['Authorization'] = "Token " + token
      } else {
        axios.defaults.headers.common['Authorization'] = ""
      }
    }
  }
  document.addEventListener('DOMContentLoaded', () => {

  // Get all "navbar-burger" elements
  const $navbarBurgers = Array.prototype.slice.call(document.querySelectorAll('.navbar-burger'), 0);

  // Check if there are any navbar burgers
  if ($navbarBurgers.length > 0) {

    // Add a click event on each of them
    $navbarBurgers.forEach( el => {
      el.addEventListener('click', () => {

        // Get the target from the "data-target" attribute
        const target = el.dataset.target;
        const $target = document.getElementById(target);

        // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
        el.classList.toggle('is-active');
        $target.classList.toggle('is-active');

      });
    });
  }

});
</script>

<style lang="scss">
@import '../node_modules/bulma';
</style>
