import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'


import VueNextSelect from 'vue-next-select'
import vSelect from "vue-select";


//axios.defaults.baseURL = 'http://127.0.0.1:8000'
axios.defaults.baseURL = 'https://apicontract.shindai.co'
//axios.defaults.baseURL = 'http://45.32.247.191:8000/'
const app = createApp(App)

app.use(store)
app.use(router,axios)

app.component("v-select", vSelect);
app.mount('#app')
