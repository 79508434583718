<template>
            <h3 class="is-size-4 mb-5">General Buyer Reports</h3>
            <div class="buttons">
              <button class="button is-primary">Report</button>
              <button class="button is-primary">Report</button>
              <button class="button is-primary">Report</button>
              <button class="button is-primary">Report</button>
            </div>
          
         
            <div class="column is-12">
              <h3 class="is-size-4 mb-5">Buyer Based Reports</h3>
              <div class="select">
                <select name="buyer" v-model="invoice.buyer">
                  <option value="">- Select buyer -</option>
                  <option v-for="buyer in buyers" v-bind:key="buyer.id" v-bind:value="buyer">
                    {{ buyer.name }}
                  </option>
                </select>
              </div>
              <div class="box mt-4" v-if="invoice.buyer">
                <p>
                  <strong>{{ invoice.buyer.name }}</strong>
                </p>
                <p>
                  <strong>Email:</strong> {{ invoice.buyer.email }}
                </p>
                <p v-if="invoice.buyer.address1">{{ invoice.buyer.address1 }}</p>
                <p v-if="invoice.buyer.address2">{{ invoice.buyer.address2 }}</p>
                <p v-if="invoice.buyer.zipcode || invoice.buyer.place">{{ invoice.buyer.zipcode }} {{ invoice.buyer.place }}</p>
                <p v-if="invoice.buyer.country">{{ invoice.buyer.country }}</p>
              </div>
            </div>
            <div class="column is-12">
              <h2 class="is-size-5 mb-4">Report Type</h2>
              <div class="buttons">
                <button class="button is-primary">Report</button>
                <button class="button is-primary">Report</button>
                <button class="button is-primary">Report</button>
                <button class="button is-primary">Report</button>
              </div>
            </div>

</template>

<script>
  import axios from 'axios'
 

  const fileDownload = require('js-file-download')
  import {
    toast
  } from 'bulma-toast'
export default {
    
    name: 'ReportSupplier',
    props: {
       
    },
    data() {
        return {
        choices: "",
        supplierchoice:"General Supplier",
        date_or_button: "",
        currency: "",
        date_start: "",
        date_end: "",
        clients: [],
        invoices: [],
              buyers: [],
        invoice: {
          client: '',
          buyer: '',
          items: [{
            item_num: 0,
            title: '',
            unit_price: '',
            quantity: 1,
            containers: 1,
            gst_rate: 0,
            net_amount: 0
          }],
          net_amount: 0,
          gst_amount: 0,
          Commission_owed: 0,
          gross_amount: 0,
          terms_and_conditions: ""
        },
        }
    },    
    async mounted() {
     await this.getBuyers()
    await this.getInvoiceList()

    },
    methods: {
              getInvoiceList() {
        axios.get('/api/v1/invoices/').then(response => {
          this.invoices = response.data
        }).catch(error => {
          console.log(JSON.stringify(error))
        })
      },
      getBuyers() {
        axios.get('/api/v1/buyers/').then(response => {
          this.buyers = response.data
        }).catch(error => {
          console.log(JSON.stringify(error))
        })
      },
              getClientDateReport() {
        const clientID = this.invoice.client.id
        console.log(clientID)
        axios.get(`/api/v1/clients/${clientID}/generate_client_all_invocies/${this.date_start}/${this.date_end}/all`, {
          responseType: 'blob',
        }).then(res => {
          fileDownload(res.data, `invoice_${clientID}.pdf`);
        }).catch(err => {
          console.log(err);
        })
      },
      getClientDatePaidReport() {
        const clientID = this.invoice.client.id
        console.log(clientID)
        axios.get(`/api/v1/clients/${clientID}/generate_client_all_invocies/${this.date_start}/${this.date_end}/PAID`, {
          responseType: 'blob',
        }).then(res => {
          fileDownload(res.data, `invoice_${clientID}.pdf`);
        }).catch(err => {
          console.log(err);
        })
      },
      getClientDateUnpaidReport() {
        const clientID = this.invoice.client.id
        console.log(clientID)
        axios.get(`/api/v1/clients/${clientID}/generate_client_all_invocies/${this.date_start}/${this.date_end}/UNPAID`, {
          responseType: 'blob',
        }).then(res => {
          fileDownload(res.data, `invoice_${clientID}.pdf`);
        }).catch(err => {
          console.log(err);
        })
      },
      getClientDateUnpaidReportMethod(currency, paid) {
        const clientID = this.invoice.client.id
        console.log(clientID)
        axios.get(`/api/v1/clients/${clientID}/generate_client_currency_invocies/${this.date_start}/${this.date_end}/${paid}/${currency}`, {
          responseType: 'blob',
        }).then(res => {
          fileDownload(res.data, `invoice_${clientID}.pdf`);
        }).catch(err => {
          console.log(err);
        })
      },
      getClientDatePresetReportMethod(currency, paid, date) {
        const clientID = this.invoice.client.id
        axios.get(`/api/v1/clients/${clientID}/generate_client_currency_invocies/${date}-1-1/${date}-12-31/${paid}/${currency}`, {
          responseType: 'blob',
        }).then(res => {
          fileDownload(res.data, `invoice_${clientID}.pdf`);
        }).catch(err => {
          console.log(err);
        })
      }
    }

}
</script>