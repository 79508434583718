<template>
    <div class="page-invoices">
        <nav class="breadcrumb" aria-label="breadcrumbs">
            <ul>
                <li><router-link to="/dashboard">Dashboard</router-link></li>
                <li class="is-active"><router-link to="/dashboard/invoices" aria-current="true">Invoices</router-link></li>
            </ul>
        </nav>

        <div class="columns is-multiline">
            <div class="column is-12">
                <h1 class="title">Invoices</h1>
            </div>
                                <div class="field has-addons">
                      <div class="control">
                        <input class="input" type="text" placeholder="Search" v-model="this.search_term" v-on:keyup.enter="getInvoices()">
                      </div>
                      <div class="control">
                        <button class="button is-info" v-on:click.prevent="getInvoices()" >
                          Search
                        </button>
                      </div>
                    </div>
            <div class="column is-12">
                <table class="table is-fullwidth">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Suppliers</th>
                            <th>Buyer</th>
                            <th>Amount</th>
                            <th>Commission</th>
                            <th>Due date</th>
                            <th>Is paid</th>
                            <th></th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr
                            v-for="invoice in filterInvoice"
                            v-bind:key="invoice.id"
                        >
                            <td>{{ invoice.invoice_number }}</td>
                            <td>{{ invoice.client_name }}</td>
                            <td>{{ invoice.buyer_name }}</td>
                            <td>{{ invoice.gross_amount }}  {{invoice.currency_type}}</td>
                            <td>{{ invoice.commission_amount }} {{invoice.currency_type}}</td>
                            <td>{{ invoice.get_due_date_formatted }}</td>
                            <td>{{ getStatusLabel(invoice) }}</td>
                            <td>
                                <router-link :to="{ name: 'Invoice', params: { id: invoice.id }}">Details</router-link>
                            </td>
                        </tr>
                    </tbody>
                </table>
                          <nav class="pagination" role="navigation" aria-label="pagination">
            <a class="pagination-previous" @click="prev()">Previous</a>
            <a class="pagination-next" @click="next()">Next page</a>
            <ul class="pagination-list">
              <li>
                <a class="pagination-link" aria-label="Goto page 1">{{this.currentPage}} / {{totalPages}}</a>
                <p class="pagination-link" aria-label="Goto page 1">Items Per Page: {{this.postPerPage}}</p>
                <p class="pagination-link" aria-label="Goto page 1">{{this.totalItems}} Items</p>
              </li>
            </ul>
          </nav>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    name: 'Invoices',
    data() {
        return {
            currentPage: 1,
            postPerPage: 15,
            invoices: [],
            search_term: "",
            api_url:"/api/v1/invoices/",
        }
    },
    mounted() {
        this.getInvoices()
    },
    methods: {
        next() {
        if (this.currentPage < this.totalPages) {
          this.currentPage++
          console.log("dd")
        }
      },
      prev() {
        if (this.currentPage > 1) {
          this.currentPage--
        }
      },
        getInvoices() {
          if (this.search_term !=='')
          {
            this.api_url = `/api/v1/invoices/?search=${this.search_term}`
          }else{
             this.api_url = "/api/v1/invoices/"
          }
            axios
                .get(this.api_url)
                .then(response => {
                  this.invoices = []
                    for (let i = 0; i < response.data.length; i++) {
                        this.invoices.push(response.data[i])
                    }
                })
                .catch(error => {
                    console.log(JSON.stringify(error))
                })
        },
        getStatusLabel(invoice) {
            if (invoice.is_commission_paid) {
                return 'Yes'
            } else {
                return 'No'
            }
        }
    },
        computed: {
      filterInvoice() {
        return this.invoices.slice((this.currentPage - 1) * this.postPerPage, this.currentPage * this.postPerPage)
      },
      totalPages() {
        return Math.ceil(this.invoices.length / this.postPerPage)
      },
      totalItems()
      {
        return this.invoices.length 
      }
    }
}
</script>
