<template>
  <div class="page-add-invoice">
    <nav class="breadcrumb" aria-label="breadcrumbs">
      <ul>
        <li>
          <router-link to="/dashboard">Dashboard</router-link>
        </li>
        <li>
          <router-link to="/dashboard/invoices">Invoices</router-link>
        </li>
        <li class="is-active">
          <router-link to="/dashboard/invoices/add" aria-current="true">Add</router-link>
        </li>
      </ul>
    </nav>
	<p>hello</p>
    <div class="columns is-multiline">
      <div class="column is-12">
        <h1 class="title">Add invoice</h1>
      </div>
      <div class="column is-12 box ">
        <h2 class="is-size-5 mb-4">Supplier</h2>
        <div class="columns">
          <v-select class="column is-10 " :value="invoice.client" v-model="invoice.client" label="name" :options="clients"></v-select>
          <router-link to="/dashboard/clients/add" class="button is-success  " style="height: auto;">Add Suppliers</router-link>
        </div>
        <div class="box mt-4" v-if="invoice.client">
          <p>
            <strong>{{ invoice.client.name }}</strong>
          </p>
          <p>
            <strong>Email:</strong> {{ invoice.client.email }}
          </p>
          <p v-if="invoice.client.address1">{{ invoice.client.address1 }}</p>
          <p v-if="invoice.client.address2">{{ invoice.client.address2 }}</p>
          <p v-if="invoice.client.zipcode || invoice.client.place">{{ invoice.client.zipcode }} {{ invoice.client.place }}</p>
          <p v-if="invoice.client.country">{{ invoice.client.country }}</p>
        </div>
        <button class=" button is-danger	" style=" width:100%" v-if="!invoice.client">Please Select a Supplier </button>
      </div>
      <div class="column is-12 box">
        <h2 class="is-size-5 mb-4">Buyer</h2>
        <div class="columns">
          <v-select class="column is-10 " :value="invoice.buyer" v-model="invoice.buyer" label="name" :options="buyers"></v-select>
          <router-link to="/dashboard/buyers/add" class="button is-success  " style="height: auto;">Add Buyer</router-link>
        </div>
        <div class="box mt-4" v-if="invoice.buyer">
          <p>
            <strong>{{ invoice.buyer.name }}</strong>
          </p>
          <p>
            <strong>Email:</strong> {{ invoice.buyer.email }}
          </p>
          <p v-if="invoice.buyer.address1">{{ invoice.buyer.address1 }}</p>
          <p v-if="invoice.buyer.address2">{{ invoice.buyer.address2 }}</p>
          <p v-if="invoice.buyer.zipcode || invoice.buyer.place">{{ invoice.buyer.zipcode }} {{ invoice.buyer.place }}</p>
          <p v-if="invoice.buyer.country">{{ invoice.buyer.country }}</p>
        </div>
        <button class=" button is-danger	" style=" width:100%" v-if="!invoice.buyer">Please Select a Client </button>
      </div>
      <div class="column is 12 box">
        <h2 class="is-size-5 mb-4">Currency</h2>
        <div class="select">
          <select name="currency" v-model="invoice.currency" @change="changeCurrency()">
            <option disabled value="">Please select one</option>
            <option>AUD</option>
            <option>EUR</option>
            <option>IDR</option>
            <option>USD</option>
          </select>
        </div>
      </div>
      <div class="column is-12 box">
        <h2 class="is-size-5 mb-4">Items</h2>
        <ItemForm v-for="item in invoice.items" v-bind:key="item.item_num" v-bind:initialItem="item" v-on:updatePrice="updateTotals"></ItemForm>
        <div class="buttons">
          <button class="button is-primary" @click="addItem">+</button>
          <button class="button is-danger" @click="removeItem">-</button>
        </div>
      </div>
      <div class="column is-12 box">
        <h2 class="is-size-5 mb-4">Shipping Information</h2>
        <div class="columns">
          <div class="field column">
            <label>Insurance</label>
            <div class="control">
              <input type="text" class="input" v-model="invoice.insurance">
            </div>
          </div>
          <div class="field column">
            <label>Shipment Before</label>
            <div class="control">
              <input type="date" class="input" v-model="invoice.shipment_before">
            </div>
          </div>
        </div>
        <div class="columns">
          <div class="field column">
            <label>Port of Shipment</label>
            <div class="control">
              <input type="text" class="input" v-model="invoice.port_of_shipment">
            </div>
          </div>
          <div class="field column">
            <label>Destination</label>
            <div class="control">
              <input type="text" class="input" v-model="invoice.destination">
            </div>
          </div>
        </div>
        <div class="columns">
          <div class="field column">
            <label>Shipping Mark</label>
            <div class="control">
              <input type="text" class="input" v-model="invoice.shipping_mark">
            </div>
          </div>
          <div class="field column">
            <label>Brand</label>
            <div class="control">
              <input type="text" class="input" v-model="invoice.brand">
            </div>
          </div>
        </div>
        <div class="columns">
        <div class="field column">
          <label>Shipment</label>
          <div class="control">
            <input type="text" class="input" v-model="invoice.shipment">
          </div>
        </div>
                <div class="field column">
          <label>ETA</label>
          <div class="control">
              <input type="date" class="input" v-model="invoice.eta">
          </div>
        </div>
        </div>
        <article class="message is-danger" v-if="shippingInformationValidation">
          <div class="message-header">
            <p>Please fill in these areas:</p>
          </div>
          <div class="message-body">
            <p v-if="!this.invoice.insurance">"Insurance" &nbsp;</p>
            <p v-if="!this.invoice.shipment_before"> "Shipment Before" &nbsp; </p>
            <p v-if="!this.invoice.port_of_shipment">"Port Of Shipment"&nbsp; </p>
            <p v-if="!this.invoice.destination"> "Destination" &nbsp; </p>
            <p v-if="!this.invoice.shipping_mark"> "Shipping Mark" &nbsp; </p>
            <p v-if="!this.invoice.brand"> "Brand" &nbsp; </p>
            <p v-if="!this.invoice.shipment"> "Shipment" &nbsp; </p>
            <p v-if="!this.invoice.eta"> "ETA" &nbsp; </p>
          </div>
        </article>
      </div>
    </div>
    <div class="column is-12 box">
      <h2 class="is-size-5 mb-4">Misc</h2>
      <div class="columns">
        <div class="field column">
          <label>Due date</label>
          <div class="control">
            <input type="date" class="input" v-model="invoice.due_date">
          </div>
        </div>
        <div class="field column">
          <label>Contract reference</label>
          <div class="control">
            <input type="text" class="input" v-model="invoice.sender_reference">
          </div>
        </div>
      </div>
      <div class="field">
        <label>Terms And Conditions</label>
        <div class="control">
          <textarea class="textarea" v-model="invoice.terms_and_conditions" rows="3" placeholder="terms and conditions"></textarea>
        </div>
      </div>
      <div class="field">
        <label>Payment Terms</label>
        <div class="control">
          <textarea class="textarea" v-model="invoice.payment_terms" rows="3" placeholder="payment terms"></textarea>
        </div>
      </div>
      <div class="field">
        <label>Buyer Notes</label>
        <div class="control">
          <textarea class="textarea" v-model="invoice.buyer_notes" rows="3" placeholder="notes"></textarea>
        </div>
      </div>
      <div class="field">
        <label>Supplier Notes</label>
        <div class="control">
          <textarea class="textarea" v-model="invoice.notes" rows="3" placeholder="notes"></textarea>
        </div>
      </div>
      <div class="field">
        <label>Commission</label>
        <div class="control">
          <input type="number" class="input" v-model="invoice.Commission_owed" @change="changeCurrency()" disabled>
        </div>
      </div>
      <article class="message is-danger" v-if="miscInformationValidation">
        <div class="message-header">
          <p>Please fill in these areas:</p>
        </div>
        <div class="message-body">
          <p v-if="!this.invoice.due_date">"Due Date" &nbsp;</p>
          <p v-if="!this.invoice.sender_reference"> "Contract Reference" &nbsp;</p>
        </div>
      </article>
    </div>
    <div class="column is-12">
      <h2 class="is-size-5 mb-4">Total</h2>
      <p>
        <strong>Net amount</strong>: {{ invoice.net_amount }} {{ this.invoice.currency }}
      </p>
      <p>
        <strong>GST amount</strong>: {{ invoice.gst_amount }} {{ this.invoice.currency }}
      </p>
      <p>
        <strong>Gross amount</strong>: {{ invoice.gross_amount }} {{ this.invoice.currency }}
      </p>
      <br>
      <p>
        <strong>Commission amount</strong>: {{ invoice.Commission_owed  }} {{ this.invoice.currency  }}
      </p>
    </div>
    <div class="column is-12">
      <button class="button is-success" @click="submitForm">Save</button>
    </div>
  </div>
</template>
<script>
  import axios from 'axios'
  import {
    toast
  } from 'bulma-toast'
  import ItemForm from '@/components/ItemForm.vue'
  export default {
    name: 'AddInvoice',
    components: {
      ItemForm
    },
    data() {
      return {
        invoices: [],
        invoice: {
          client: '',
          buyer: '',
          items: [{
            item_num: 0,
            title: '',
            unit_price: '',
            quantity: 1,
            containers: 1,
            gst_rate: 0,
            net_amount: 0,
            commission_item: 0,
          }],
          currency: 'AUD',
          net_amount: 0,
          gst_amount: 0,
          Commission_owed: 0,
          gross_amount: 0,
          terms_and_conditions: "",
          payment_terms: "",
          notes: "",
          commission_aud: 0,
          commission_eur: 0,
          commission_idr: 0,
          commission_usd: 0,
        },
        clients: [],
        buyers: [],
        clientHolder: {},
        commission: {
          client: '',
          amount: 0,
          type: "PAID"
        },
        supplierValidation: false,
        buyerValidation: false,
        insuranceValidation: false,
        shipmentBeforeValidation: false,
        portOfShipmentValidation: false,
        destinationValidation: false,
        shippingValidation: false,
        brandValidation: false,
        shipmentValidation: false,
        dueDateValidation: false,
        contractValidation: false,
        termsValidation: false,
      }
    },
    async mounted() {
      await this.getClients()
      await this.getBuyers()
      await this.getInvoiceList()
    },
    methods: {
      changeCurrency() {
        switch (this.invoice.currency) {
          case 'AUD':
            parseFloat(this.clientHolder.commission_aud = this.invoice.Commission_owed).toFixed(2)
            console.log(this.invoice.Commission_owed + " AUD")
            break;
          case 'EUR':
            this.invoice.commission_eur = this.invoice.Commission_owed
            console.log(this.invoice.Commission_owed + " EUR")
            break;
          case 'IDR':
            this.invoice.commission_idr = this.invoice.Commission_owed
            console.log(this.invoice.Commission_owed + " IDR")
            break;
          case 'USD':
            this.invoice.commission_usd = this.invoice.Commission_owed
            console.log(this.invoice.Commission_owed + " USD")
            break;
          default:
            this.invoice.commission_aud = this.invoice.Commission_owed
            console.log(this.invoice.Commission_owed + " AUD")
        }
      },
      getClients() {
        axios.get('/api/v1/clients/').then(response => {
          this.clients = response.data
        }).catch(error => {
          console.log(JSON.stringify(error))
          toast({
              message: 'Issue has occured',
              type: 'is-danger',
              dismissible: true,
              pauseOnHover: true,
              duration: 2000,
              position: 'bottom-right',
            })
            this.$router.push('/dashboard/invoices')
        })
      },
      getBuyers() {
        axios.get('/api/v1/buyers/').then(response => {
          this.buyers = response.data
        }).catch(error => {
          console.log(JSON.stringify(error))
          toast({
              message: 'Issue has occured',
              type: 'is-danger',
              dismissible: true,
              pauseOnHover: true,
              duration: 2000,
              position: 'bottom-right',
            })
            this.$router.push('/dashboard/invoices')
        })
      },
      getInvoiceList() {
        axios.get('/api/v1/invoices/').then(response => {
          this.invoices = response.data
        }).catch(error => {
          console.log(JSON.stringify(error))
          toast({
              message: 'Issue has occured',
              type: 'is-danger',
              dismissible: true,
              pauseOnHover: true,
              duration: 2000,
              position: 'bottom-right',
            })
            this.$router.push('/dashboard/invoices')
        })
      },
      addItem() {
        this.invoice.items.push({
          item_num: this.invoice.items.length,
          title: '',
          unit_price: '',
          quantity: 1,
          containers: 1,
          gst_rate: 0,
          net_amount: 0,
          commission_item: 0,
          commission_item_total: 0,
        })
      },
      removeItem() {
        if (this.invoice.items.length > 1) {
          this.invoice.items.pop()
        }
      },
      updateTotals(changedItem) {
        let net_amount = 0
        let gst_amount = 0
        let commission_item = 0
        let item = this.invoice.items.filter(i => i.item_num === changedItem.item_num)
        item = changedItem
        for (let i = 0; i < this.invoice.items.length; i++) {
          const gst_rate = this.invoice.items[i].gst_rate
          gst_amount += this.invoice.items[i].net_amount * (gst_rate / 100)
          net_amount += +this.invoice.items[i].net_amount
          this.invoice.items[i].net_amount = parseFloat(this.invoice.items[i].net_amount).toFixed(2)
          commission_item += +parseFloat(this.invoice.items[i].commission_item_total).toFixed(2)
          //commission_item = parseFloat(commission_item).toFixed(2)
        }
        this.invoice.net_amount = net_amount
        this.invoice.net_amount = parseFloat(net_amount).toFixed(2)
        
        this.invoice.gst_amount = gst_amount


        this.invoice.gross_amount = (+net_amount) + (+gst_amount)
        this.invoice.gross_amount = parseFloat(this.invoice.gross_amount).toFixed(2)

        this.invoice.discount_amount = 0
        this.invoice.Commission_owed = parseFloat(commission_item).toFixed(2)
      },
      submitForm() {
        if (!!this.invoice.client && !!this.invoice.buyer && !!this.invoice.insurance && !!this.invoice.shipment_before && !!this.invoice.port_of_shipment && !!this.invoice.destination && !!this.invoice.shipping_mark && !!this.invoice.brand && !!this.invoice.shipment && !!this.invoice.due_date && !!this.invoice.sender_reference) {
          console.log("SENT")
          this.invoice.client_name = this.invoice.client.name
          this.invoice.client_email = this.invoice.client.email
          this.invoice.client_org_number = this.invoice.client.org_number
          this.invoice.client_address1 = this.invoice.client.address1
          this.invoice.client_address2 = this.invoice.client.address2
          this.invoice.client_zipcode = this.invoice.client.zipcode
          this.invoice.client_place = this.invoice.client.place
          this.invoice.client_country = this.invoice.client.country
          this.invoice.client_contact_person = this.invoice.client.contact_person
          this.invoice.client_contact_reference = this.invoice.client.contact_reference
          this.invoice.client = this.invoice.client.id
          let tempduedate = new Date(this.invoice.due_date)
          this.invoice.due_date = tempduedate
          this.invoice.buyer_name = this.invoice.buyer.name
          this.invoice.buyer_email = this.invoice.buyer.email
          this.invoice.buyer_org_number = this.invoice.buyer.org_number
          this.invoice.buyer_address1 = this.invoice.buyer.address1
          this.invoice.buyer_address2 = this.invoice.buyer.address2
          this.invoice.buyer_zipcode = this.invoice.buyer.zipcode
          this.invoice.buyer_place = this.invoice.buyer.place
          this.invoice.buyer_country = this.invoice.buyer.country
          this.invoice.buyer_contact_person = this.invoice.buyer.contact_person
          this.invoice.buyer_contact_reference = this.invoice.buyer.contact_reference
          this.invoice.buyer = this.invoice.buyer.id
          this.invoice.commission_amount = parseFloat( this.invoice.Commission_owed).toFixed(2)
          this.invoice.currency_type = this.invoice.currency
          this.invoice.terms_and_conditions = this.invoice.terms_and_conditions
          this.invoice.payment_terms = this.invoice.payment_terms
          this.invoice.notes = this.invoice.notes
          this.invoice.buyer_notes = this.invoice.buyer_notes
          this.invoice.insurance = this.invoice.insurance
          let tempshipment_before = new Date(this.invoice.shipment_before)
          this.invoice.shipment_before = tempshipment_before
          this.invoice.port_of_shipment = this.invoice.port_of_shipment
          this.invoice.destination = this.invoice.destination
          this.invoice.shipping_mark = this.invoice.shipping_mark
          this.invoice.brand = this.invoice.brand
          this.invoice.shipment = this.invoice.shipment
          let tempeta_date = new Date(this.invoice.eta)
          this.invoice.eta = tempeta_date
          axios.post('/api/v1/invoices/', this.invoice).then(response => {
            toast({
              message: 'The invoice was added',
              type: 'is-success',
              dismissible: true,
              pauseOnHover: true,
              duration: 2000,
              position: 'bottom-right',
            })
            this.getClient()
          }).catch(error => {
            console.log(JSON.stringify(error))
            toast({
              message: 'Issue has occured',
              type: 'is-danger',
              dismissible: true,
              pauseOnHover: true,
              duration: 2000,
              position: 'bottom-right',
            })
            this.$router.push('/dashboard/invoices')
          })
        }else
        {console.log("NOT SENT")}
      },
      getClient() {
        axios.get(`/api/v1/clients/${this.invoice.client}`).then(response => {
          this.clientHolder = response.data
          console.log(this.clientHolder)
          let temp = parseFloat(this.clientHolder.commission_owed)
          temp += parseFloat(this.invoice.Commission_owed)

          let newInvoiceNumber = this.invoices[0].id
          newInvoiceNumber++
          console.log(newInvoiceNumber)
          this.clientHolder.commission_total = 0
          this.clientHolder.commission_owed = 0
          switch (this.invoice.currency) {
            case 'AUD':
              this.clientHolder.commission_aud = parseFloat(this.clientHolder.commission_aud) + parseFloat(this.invoice.Commission_owed)
              this.clientHolder.commission_aud_total = parseFloat(this.clientHolder.commission_aud_total) + parseFloat(this.invoice.Commission_owed)
              console.log(this.invoice.Commission_owed + " AUD" + this.clientHolder.commission_aud)
              this.clientHolder.commission_aud = parseFloat(this.clientHolder.commission_aud).toFixed(2)
              this.clientHolder.commission_aud_total = parseFloat(this.clientHolder.commission_aud_total).toFixed(2)
              break;
            case 'EUR':
              this.clientHolder.commission_eur = parseFloat(this.clientHolder.commission_eur) + parseFloat(this.invoice.Commission_owed)
              this.clientHolder.commission_eur_total = parseFloat(this.clientHolder.commission_eur_total) + parseFloat(this.invoice.Commission_owed)
              console.log(this.invoice.Commission_owed + " EUR")
              break;
            case 'IDR':
              this.clientHolder.commission_idr = parseFloat(this.clientHolder.commission_idr) + parseFloat(this.invoice.Commission_owed)
              this.clientHolder.commission_idr_total = parseFloat(this.clientHolder.commission_idr_total) + parseFloat(this.invoice.Commission_owed)
              console.log(this.invoice.Commission_owed + " IDR")
              break;
            case 'USD':
              this.clientHolder.commission_usd = parseFloat(this.clientHolder.commission_usd) + parseFloat(this.invoice.Commission_owed)
              this.clientHolder.commission_usd_total = parseFloat(this.clientHolder.commission_usd_total) + parseFloat(this.invoice.Commission_owed)
              console.log(this.invoice.Commission_owed + " USD")
              break;
            default:
              this.clientHolder.commission_aud = parseFloat(this.clientHolder.commission_aud) + parseFloat(this.invoice.Commission_owed)
              console.log(this.invoice.Commission_owed + " AUD")
                            this.clientHolder.commission_aud = parseFloat(this.clientHolder.commission_aud).toFixed(2)
              this.clientHolder.commission_aud_total = parseFloat(this.clientHolder.commission_aud_total).toFixed(2)
          }
          console.log(this.clientHolder.invoices.length)
          for (let i = 0; i < this.clientHolder.invoices.length; i++) {
            this.clientHolder.commission_total += +parseFloat(this.clientHolder.invoices[i].commission_amount).toFixed(2)
            //this.clientHolder.commission_total.toFixed(2)
            console.log(this.clientHolder)
          }
          for (let i = 0; i < this.clientHolder.invoices.length; i++) {
            if (!this.clientHolder.invoices[i].is_commission_paid) {
              this.clientHolder.commission_owed += +parseFloat(this.clientHolder.invoices[i].commission_amount).toFixed(2)
              //this.clientHolder.commission_owed.toFixed(2)

              console.log(this.clientHolder)
            }
          }
          this.commission = {
            client: this.invoice.client,
            amount: this.invoice.Commission_owed,
            type: 'ADDED',
            invoices: [newInvoiceNumber],
            currency_type: this.invoice.currency
          }
          console.log(this.commission)
          axios.post(`/api/v1/commission/`, this.commission).then(response => {
            console.log("HELP")
            toast({
              message: 'The commission was added',
              type: 'is-success',
              dismissible: true,
              pauseOnHover: true,
              duration: 2000,
              position: 'bottom-right',
            })
          }).catch(error => {
            console.log(JSON.stringify(error))
            toast({
              message: 'Issue has occured',
              type: 'is-danger',
              dismissible: true,
              pauseOnHover: true,
              duration: 2000,
              position: 'bottom-right',
            })
            this.$router.push('/dashboard/invoices')
          })
          axios.patch(`/api/v1/clients/${this.invoice.client}/`, this.clientHolder).then(response => {
            this.$router.push('/dashboard/invoices')
          }).catch(error => {
            console.log(JSON.stringify(error))
            toast({
              message: 'Issue has occured',
              type: 'is-danger',
              dismissible: true,
              pauseOnHover: true,
              duration: 2000,
              position: 'bottom-right',
            })
            this.$router.push('/dashboard/invoices')
          })
        }).catch(error => {
          console.log(JSON.stringify(error))
          toast({
              message: 'Issue has occured',
              type: 'is-danger',
              dismissible: true,
              pauseOnHover: true,
              duration: 2000,
              position: 'bottom-right',
            })
            this.$router.push('/dashboard/invoices')
        })
      },
    },
    computed: {
      shippingInformationValidation() {
        if (!this.invoice.insurance || !this.invoice.shipment_before || !this.invoice.port_of_shipment || !this.invoice.destination || !this.invoice.shipping_mark || !this.invoice.brand || !this.invoice.shipment || !this.invoice.eta) {
          return true
        } else {
          return false
        }
      },
      miscInformationValidation() {
        if (!this.invoice.due_date || !this.invoice.sender_reference) {
          return true
        } else {
          return false
        }
      }
    }
  }
</script>
<style lang="scss">
  .select,
  .select select {
    width: 100%;
  }
</style>
