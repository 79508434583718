<template>
  <div class="page-reports">
    <nav class="breadcrumb" aria-label="breadcrumbs">
      <ul>
        <li>
          <router-link to="/dashboard">Dashboard</router-link>
        </li>
        <li class="is-active">
          <router-link to="/dashboard/reports" aria-current="true">Reports</router-link>
        </li>
      </ul>
    </nav>

    <div class="column is-12 mb-4">
      <div class="box">
        <h3 class="is-size-4 mb-5">Reports</h3>
        <div class="column is-12">


          <div class="box mt-4" >
            <ReportSupplier ></ReportSupplier>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import axios from 'axios'
  import ReportSupplier from '@/components/ReportSupplier.vue'
  import ReportBuyer from '@/components/ReportBuyer.vue'

  const fileDownload = require('js-file-download')
  import {
    toast
  } from 'bulma-toast'

  export default {
    name: 'AddInvoice',
    components: {
      ReportSupplier,
      ReportBuyer
    },
    data() {
      return {
        choices: "",
        supplierchoice:"General Supplier",
        date_or_button: "",
        currency: "",
        date_start: "",
        date_end: "",
        invoices: [],
        invoice: {
          client: '',
          buyer: '',
          items: [{
            item_num: 0,
            title: '',
            unit_price: '',
            quantity: 1,
            containers: 1,
            gst_rate: 0,
            net_amount: 0
          }],
          net_amount: 0,
          gst_amount: 0,
          Commission_owed: 0,
          gross_amount: 0,
          terms_and_conditions: ""
        },
        clients: [],
        buyers: [],
        clientHolder: {},
        commission: {
          client: '',
          amount: 0,
          type: "PAID"
        }
      }
    },
    async mounted() {

      await this.getBuyers()
      await this.getInvoiceList()
    },
    methods: {

      getBuyers() {
        axios.get('/api/v1/buyers/').then(response => {
          this.buyers = response.data
        }).catch(error => {
          console.log(JSON.stringify(error))
        })
      },
      getInvoiceList() {
        axios.get('/api/v1/invoices/').then(response => {
          this.invoices = response.data
        }).catch(error => {
          console.log(JSON.stringify(error))
        })
      },

 

    }
  }
</script>
<style lang="scss">
  .select,
  .select select {
    width: 100%;
  }
</style>