<template>
    <div class="page-buyer">
        <nav class="breadcrumb" aria-label="breadcrumbs">
            <ul>
                <li><router-link to="/dashboard">Dashboard</router-link></li>
                <li><router-link to="/dashboard/buyers">Buyers</router-link></li>
                <li class="is-active"><router-link :to="{ name: 'Buyer', params: { id: this.$route.params.id }}" aria-current="true">{{ buyer.name }}</router-link></li>
            </ul>
        </nav>
        
        <div class="columns is-multiline">
            <div class="column is-12">
                <h1 class="title">{{ buyer.name }}</h1>

                <router-link :to="{ name: 'EditBuyer', params: { id: this.$route.params.id}}" class="button is-light mt-4">Edit</router-link>
            </div>

            <div class="column is-12">
                <h2 class="subtitle">Contact details</h2>

                <p><strong>{{ buyer.name }}</strong></p>
                
                <p v-if="buyer.address1">{{ buyer.address1 }}</p>
                <p v-if="buyer.address2">{{ buyer.address2 }}</p>
                <p v-if="buyer.zipcode || buyer.place">{{ buyer.zipcode }} {{ buyer.place }}</p>
                <p v-if="buyer.country">{{ buyer.country }}</p>
            </div>

            <div class="column is-12" v-if="unpaidInvoices.length">
                <div class="box">
                    <h2 class="subtitle">Unpaid invoices</h2>

                    <table class="table is-fullwidth">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Amount</th>
                                <th>Due date</th>
                                <th></th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr
                                v-for="invoice in filterInvoiceUnpaid"
                                v-bind:key="invoice.id"
                            >
                                <td>{{ invoice.invoice_number }}</td>
                                <td>{{ invoice.gross_amount }}</td>
                                <td>{{ invoice.get_due_date_formatted }}</td>
                                <td>
                                    <router-link :to="{ name: 'Invoice', params: { id: invoice.id } }">Details</router-link>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                      <nav class="pagination" role="navigation" aria-label="pagination">
                        <a class="pagination-previous" @click="prevUnpaid()">Previous</a>
                        <a class="pagination-next" @click="nextUnpaid()">Next page</a>
                        <ul class="pagination-list">
                          <li>
                            <a class="pagination-link" aria-label="Goto page 1">{{this.currentPageUnpaid}} / {{totalPagesUnpaid}}</a>
                          </li>
                        </ul>
                      </nav>                    
                </div>
            </div>

            <div class="column is-12" v-if="paidInvoices.length">
                <div class="box">
                    <h2 class="subtitle">Paid invoices</h2>

                    <table class="table is-fullwidth">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Amount</th>
                                <th>Due date</th>
                                <th></th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr
                                v-for="invoice in filterInvoice"
                                v-bind:key="invoice.id"
                            >
                                <td>{{ invoice.invoice_number }}</td>
                                <td>{{ invoice.gross_amount }}</td>
                                <td>{{ invoice.get_due_date_formatted }}</td>
                                <td>
                                    <router-link :to="{ name: 'Invoice', params: { id: invoice.id } }">Details</router-link>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <nav class="pagination" role="navigation" aria-label="pagination">
                      <a class="pagination-previous" @click="prev()">Previous</a>
                      <a class="pagination-next" @click="next()">Next page</a>
                      <ul class="pagination-list">
                        <li>
                          <a class="pagination-link" aria-label="Goto page 1">{{this.currentPage}} / {{totalPages}}</a>
                        </li>
                      </ul>
                    </nav>                    
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    name: 'Buyer',
    data () {
        return {
            currentPage: 1,
            postPerPage: 5,
            currentPageUnpaid: 1,
            postPerPageUnpaid: 5,
            
            buyer: {
                invoices: []
            }
        }
    },
    mounted() {
        this.getClient()
    },
    methods: {
        next() {
          if (this.currentPage < this.totalPages) {
            this.currentPage++
            console.log("dd")
          }
        },
        prev() {
          if (this.currentPage > 1) {
            this.currentPage--
          }
        },
        nextUnpaid() {
          if (this.currentPageUnpaid < this.totalPagesUnpaid) {
            this.currentPageUnpaid++
            console.log("dd")
          }
        },
        prevUnpaid() {
          if (this.currentPageUnpaid > 1) {
            this.currentPageUnpaid--
          }
        },        
        getClient() {
            const buyerID = this.$route.params.id

            axios
                .get(`/api/v1/buyers/${buyerID}`)
                .then(response => {
                    this.buyer = response.data
                })
                .catch(error => {
                    console.log(JSON.stringify(error))
                })
        }
    },
    computed: {
        unpaidInvoices() {
            return this.buyer.invoices.filter(invoice => invoice.is_paid === false)
        },
        paidInvoices() {
            return this.buyer.invoices.filter(invoice => invoice.is_paid === true)
        },
        filterInvoice() {
            return this.buyer.invoices.filter(invoice => invoice.is_paid === true).slice((this.currentPage - 1) * this.postPerPage, this.currentPage * this.postPerPage)
      },
        totalPages() {
            return Math.ceil(this.buyer.invoices.filter(invoice => invoice.is_paid === true).length / this.postPerPage)
      },
        filterInvoiceUnpaid() {
            return this.buyer.invoices.filter(invoice => invoice.is_paid === false).slice((this.currentPageUnpaid - 1) * this.postPerPageUnpaid, this.currentPageUnpaid * this.postPerPageUnpaid)
      },
        totalPagesUnpaid() {
            return Math.ceil(this.buyer.invoices.filter(invoice => invoice.is_paid === false).length / this.postPerPageUnpaid)
      },
    }
}
</script>