<template>
  <div class="page-clients">
    <nav class="breadcrumb" aria-label="breadcrumbs">
      <ul>
        <li>
          <router-link to="/dashboard">Dashboard</router-link>
        </li>
        <li class="is-active">
          <router-link to="/dashboard/clients" aria-current="true">Suppliers</router-link>
        </li>
      </ul>
    </nav>
    <div class="columns is-multiline">
      <div class="column is-12">
        <h1 class="title column">Suppliers</h1>
        <div class="column">
          <div class="field has-addons">
            <div class="control">
              <input class="input" type="text" placeholder="Search" v-model="this.search_term" v-on:keyup.enter="getClients()">
            </div>
            <div class="control">
              <button class="button is-info" v-on:click.prevent="getClients()"> Search </button>
            </div>
          </div>
          <router-link :to="{ name: 'AddClient' }" class="button is-light mt-4">Add Supplier</router-link>
        </div>
      </div>
      <div class="column is-3" v-for="client in clients" v-bind:key="client.id">
        <div class="box">
          <h3 class="is-size-4 mb-4">{{ client.name }}</h3>
          <router-link :to="{ name: 'Client', params: { id: client.id }}" class="button is-light">Details</router-link>
        </div>
      </div>

    

    </div>
                       
            <nav class="pagination" role="navigation" aria-label="pagination">
            <a class="pagination-previous" @click="prev()">Previous</a>
            <a class="pagination-next" @click="next()">Next page</a>
            <ul class="pagination-list">
              <li>
                <a class="pagination-link" aria-label="Goto page 1">{{this.currentPage}} / {{totalPages}}</a>
                <p class="pagination-link" aria-label="Goto page 1">Items Per Page: {{this.postPerPage}}</p>
                <p class="pagination-link" aria-label="Goto page 1">{{this.totalItems}} Items</p>
              </li>
            </ul>
          </nav>
  </div>
</template>
<script>
  import axios from 'axios'
  export default {
    name: 'Clients',
    data() {
      return {
        clients: [],
        search_term: "",
        api_url: "/api/v1/clients/",
        currentPage: 1,
        postPerPage: 15,
      }
    },
    mounted() {
      this.getClients()
    },
    methods: {
      next() {
        if (this.currentPage < this.totalPages) {
          this.currentPage++
        }
      },
      prev() {
        if (this.currentPage > 1) {
          this.currentPage--
        }
      },
      getClients() {
        if (this.search_term !== '') {
          this.api_url = `/api/v1/clients/?search=${this.search_term}`
        } else {
          this.api_url = "/api/v1/clients/"
        }
        axios.get(this.api_url).then(response => {
          this.clients = []
          for (let i = 0; i < response.data.length; i++) {
            this.clients.push(response.data[i])
          }
        }).catch(error => {
          console.log(JSON.stringify(error))
        })
      },
    },
    computed: {
      filterInvoice() {
        return this.clients.slice((this.currentPage - 1) * this.postPerPage, this.currentPage * this.postPerPage)
      },
      totalPages() {
        return Math.ceil(this.clients.length / this.postPerPage)
      },
      totalItems() {
        return this.clients.length
      }
    }
  }
</script>