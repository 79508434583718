<template>
  <div class="page-client">
    <nav class="breadcrumb" aria-label="breadcrumbs">
      <ul>
        <li>
          <router-link to="/dashboard">Dashboard</router-link>
        </li>
        <li>
          <router-link to="/dashboard/clients">Suppliers</router-link>
        </li>
        <li class="is-active">
          <router-link :to="{ name: 'Client', params: { id: this.$route.params.id }}" aria-current="true">{{ client.name }}</router-link>
        </li>
      </ul>
    </nav>
    <div class="columns is-multiline">
      <div class="column is-12">
        <h1 class="title">{{ client.name }}</h1>
        <router-link :to="{ name: 'EditClient', params: { id: this.$route.params.id}}" class="button is-light mt-4">Edit</router-link>
      </div>
      <div class="column is-12">
        <h2 class="subtitle">Contact details</h2>
        <p>
          <strong>{{ client.name }}</strong>
        </p>
        <p v-if="client.address1">{{ client.address1 }}</p>
        <p v-if="client.address2">{{ client.address2 }}</p>
        <p v-if="client.zipcode || client.place">{{ client.zipcode }} {{ client.place }}</p>
        <p v-if="client.country">{{ client.country }}</p>
        <p v-if="client.currency_type">Currency: {{ client.currency_type }} </p>
      </div>
      <div class="column is-12" v-if="unpaidInvoices.length">
        <div class="box">
          <h2 class="subtitle">Unpaid invoices</h2>
          <table class="table is-fullwidth">
            <thead>
              <tr>
                <th>#</th>
                <th>Amount</th>
                <th>Commission</th>
                <th>Due date</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="invoice in filterInvoiceUnpaid" v-bind:key="invoice.id">
                <td>{{ invoice.invoice_number }}</td>
                <td>{{ invoice.gross_amount }} {{invoice.currency_type}}</td>
                <td>{{ invoice.commission_amount }} {{invoice.currency_type}}</td>
                <td>{{ invoice.get_due_date_formatted }}</td>
                <td>
                  <router-link :to="{ name: 'Invoice', params: { id: invoice.id } }">Details</router-link>
                </td>
              </tr>
            </tbody>
          </table>
          <nav class="pagination" role="navigation" aria-label="pagination">
            <a class="pagination-previous" @click="prevUnpaid()">Previous</a>
            <a class="pagination-next" @click="nextUnpaid()">Next page</a>
            <ul class="pagination-list">
              <li>
                <a class="pagination-link" aria-label="Goto page 1">{{this.currentPageUnpaid}} / {{totalPagesUnpaid}}</a>
                <a class="pagination-link" aria-label="Goto page 1">{{totalItemsInvoiceUnpaid}} Items</a>

              </li>
            </ul>
          </nav>
        </div>
      </div>
      <div class="column is-12" v-if="paidInvoices.length">
        <div class="box">
          <h2 class="subtitle">Paid invoices</h2>
          <table class="table is-fullwidth">
            <thead>
              <tr>
                <th>#</th>
                <th>Amount</th>
                <th>Commission</th>
                <th>Due date</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="invoice in filterInvoice" v-bind:key="invoice.id">
                <td>{{ invoice.invoice_number }} </td>
                <td>{{ invoice.gross_amount }} {{invoice.currency_type}}</td>
                <td>{{ invoice.commission_amount }} {{invoice.currency_type}}</td>
                <td>{{ invoice.get_due_date_formatted }}</td>
                <td>
                  <router-link :to="{ name: 'Invoice', params: { id: invoice.id } }">Details</router-link>
                </td>
              </tr>
            </tbody>
          </table>
          <nav class="pagination" role="navigation" aria-label="pagination">
            <a class="pagination-previous" @click="prev()">Previous</a>
            <a class="pagination-next" @click="next()">Next page</a>
            <ul class="pagination-list">
              <li>
                <a class="pagination-link" aria-label="Goto page 1">{{this.currentPage}} / {{totalPages}}</a>
                <a class="pagination-link" aria-label="Goto page 1">{{totalItemsInvoicePaid}} Items</a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
      <div class="column is-12">
        <div class="box">
          <div class='columns'>
            <h2 class="subtitle column is-3">Commission: {{client.commission_owed}} {{currency_selected}}</h2>
            <v-select class="column is-6" :value="currency_selected" v-model="currency_selected" :options="options" :input="currencyCommission()"></v-select>
          </div>
          <div class="buttons">
            <button class="button is-info" @click="getPdf()">Generate UNPAID {{currency_selected}} Report</button>
            <button class="button is-info" @click="getPdfALLPAID()">Generate PAID {{currency_selected}} Report</button>
          </div>
          <div class="columns is-12"></div>
          <table class="table is-fullwidth">
            <thead>
              <tr>
                <th>Amount</th>
                <th>Net Invoice Amount</th>
                <th>Date</th>
                <th>Creation Type</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="commission in filterInvoiceCurrency" v-bind:key="commission.amount">
                <td class="is-success" v-if="commission.is_commission_paid">{{ commission.commission_amount }}</td>
                <td class="is-danger" v-else>{{ commission.commission_amount }}</td>
                <td class="is-success" v-if="commission.is_commission_paid">{{ commission.net_amount }}</td>
                <td class="is-danger" v-else>{{ commission.net_amount }}</td>
                <td class="is-success" v-if="commission.is_commission_paid">{{commission.get_due_date_formatted}}</td>
                <td class="is-danger" v-else>{{commission.get_due_date_formatted}}</td>
                <td class="is-success" v-if="commission.is_commission_paid">
                  <router-link :to="{ name: 'Invoice', params: { id: commission.id }}">{{ commission.invoice_type }}: {{ commission.id }}</router-link>
                </td>
                <td class="is-danger" v-else>
                  <router-link :to="{ name: 'Invoice', params: { id: commission.id }}">{{ commission.invoice_type }}: {{ commission.id }}</router-link>
                </td>
              </tr>
            </tbody>
          </table>
                   <nav class="pagination" role="navigation" aria-label="pagination">
            <a class="pagination-previous" @click="prevCurrency()">Previous</a>
            <a class="pagination-next" @click="nextCurrency()">Next page</a>
            <ul class="pagination-list">
              <li>
                <a class="pagination-link" aria-label="Goto page 1">{{this.currentPageCurrency}} / {{totalPagesCurrency}}</a>
                 <a class="pagination-link" aria-label="Goto page 1">{{totalItemsCurrency}} Items</a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import axios from 'axios'
  import {
    toast
  } from 'bulma-toast'
  import "vue-select/dist/vue-select.css";
  const fileDownload = require('js-file-download')
  export default {
    name: 'Client',
    data() {
      return {
        currentPage: 1,
        postPerPage: 5,
        currentPageUnpaid: 1,
        postPerPageUnpaid: 5,
        currentPageCurrency: 1,
        postPerPageCurrency: 5,
        client: {
          invoices: [],
        },
        commission_paid: 0,
        commission_add: 0,
        commission: {
          client: '',
          amount: 0,
          type: "PAID",
          invoices: [],
          creation_type: ""
        },
        options: ['AUD', 'EUR', 'IDR', 'USD'],
        currency_selected: 'AUD'
      }
    },
    mounted() {
      this.getClient()
    },
    computed: {
      unpaidInvoices() {
        return this.client.invoices.filter(invoice => invoice.is_paid === false)
      },
      paidInvoices() {
        return this.client.invoices.filter(invoice => invoice.is_paid === true)
      },
      updateCurrency() {
        return this.client.invoices.filter(invoice => invoice.currency_type === this.currency_selected)
      },
      filterInvoice() {
        return this.client.invoices.filter(invoice => invoice.is_paid === true).slice((this.currentPage - 1) * this.postPerPage, this.currentPage * this.postPerPage)
      },
      totalPages() {
        return Math.ceil(this.client.invoices.filter(invoice => invoice.is_paid === true).length / this.postPerPage)
      },
      filterInvoiceUnpaid() {
        return this.client.invoices.filter(invoice => invoice.is_paid === false).slice((this.currentPageUnpaid - 1) * this.postPerPageUnpaid, this.currentPageUnpaid * this.postPerPageUnpaid)
      },
      totalPagesUnpaid() {
        return Math.ceil(this.client.invoices.filter(invoice => invoice.is_paid === false).length / this.postPerPageUnpaid)
      },
      filterInvoiceCurrency() {
        return this.client.invoices.filter(invoice => invoice.currency_type === this.currency_selected).slice((this.currentPageCurrency - 1) * this.postPerPageCurrency, this.currentPageCurrency * this.postPerPageCurrency)
      },
      totalPagesCurrency() {
        return Math.ceil(this.client.invoices.filter(invoice => invoice.currency_type === this.currency_selected).length / this.postPerPageCurrency)
      }   ,
      totalItemsInvoiceUnpaid()
      {
        return this.client.invoices.filter(invoice => invoice.is_paid === false).length
      },
      totalItemsInvoicePaid()
      {
        return this.client.invoices.filter(invoice => invoice.is_paid === true).length
      },
      totalItemsCurrency()
      {
        return this.client.invoices.filter(invoice => invoice.currency_type === this.currency_selected).length
      }      
    },
    methods: {
      next() {
        if (this.currentPage < this.totalPages) {
          this.currentPage++
          console.log("dd")
        }
      },
      prev() {
        if (this.currentPage > 1) {
          this.currentPage--
        }
      },
      nextUnpaid() {
        if (this.currentPageUnpaid < this.totalPagesUnpaid) {
          this.currentPageUnpaid++
          console.log("dd")
        }
      },
      prevUnpaid() {
        if (this.currentPageUnpaid > 1) {
          this.currentPageUnpaid--
        }
      },
      nextCurrency() {
        if (this.currentPageCurrency < this.totalPagesCurrency) {
          this.currentPageCurrency++
          console.log("dd")
        }
      },
      prevCurrency() {
        if (this.currentPageCurrency > 1) {
          this.currentPageCurrency--
        }
      },
      currencyCalculation(currency_swapout) {
        switch (currency_swapout) {
          case 'AUD':
            this.client.commission_owed = this.client.commission_aud
            break;
          case 'EUR':
            this.client.commission_owed = this.client.commission_eur
            break;
          case 'IDR':
            this.client.commission_owed = this.client.commission_idr
            break;
          case 'USD':
            this.client.commission_owed = this.client.commission_usd
            break;
          default:
            this.client.commission_owed = this.client.commission_aud
        }
      },
      currencyCommission() {
        console.log(this.currency_selected)
        this.currencyCalculation(this.currency_selected)
      },
      getClient() {
        const clientID = this.$route.params.id
        axios.get(`/api/v1/clients/${clientID}`).then(response => {
          this.client = response.data
        }).catch(error => {
          console.log(JSON.stringify(error))
        })
      },
      paidCommission() {
        if (parseFloat(this.commission_paid) > 0) {
          let temp = parseFloat(this.client.commission_owed)
          temp -= parseFloat(this.commission_paid)
          this.client.commission_owed = temp
          console.log(this.client.id)
          this.commission = {
            client: this.client.id,
            amount: parseFloat(this.commission_paid),
            type: 'PAID',
            invoices: [],
            creation_type: "MANUAL"
          }
          axios.post(`/api/v1/commission/`, this.commission).then(response => {
            console.log("HELP")
            toast({
              message: 'The client was added',
              type: 'is-success',
              dismissible: true,
              pauseOnHover: true,
              duration: 2000,
              position: 'bottom-right',
            })
            axios.patch(`/api/v1/clients/${this.client.id}/`, this.client).then(response => {
              this.getClient()
            }).catch(error => {
              console.log(JSON.stringify(error))
            })
          }).catch(error => {
            console.log(JSON.stringify(error))
          })
        }
      },
      addCommission() {
        if (parseFloat(this.commission_add) > 0) {
          let temp = parseFloat(this.client.commission_owed)
          temp += parseFloat(this.commission_add)
          this.client.commission_owed = temp
          this.commission = {
            client: this.client.id,
            amount: parseFloat(this.commission_add),
            type: 'ADDED',
            invoices: [],
            creation_type: "MANUAL"
          }
          axios.post(`/api/v1/commission/`, this.commission).then(response => {
            console.log("HELP")
            toast({
              message: 'The client was added',
              type: 'is-success',
              dismissible: true,
              pauseOnHover: true,
              duration: 2000,
              position: 'bottom-right',
            })
            axios.patch(`/api/v1/clients/${this.client.id}/`, this.client).then(response => {
              this.getClient()
            }).catch(error => {
              console.log(JSON.stringify(error))
            })
          }).catch(error => {
            console.log(JSON.stringify(error))
          })
        }
      },
      getPdf() {
        const invoiceID = this.$route.params.id
        axios.get(`/api/v1/clients/${this.client.id}/generate_pdf_commission/${this.currency_selected}/TRUE`, {
          responseType: 'blob',
        }).then(res => {
          let tempname = 'invoice_' + this.client.name + this.currency_selected + '_' + new Date().toLocaleString() + '.pdf'
          fileDownload(res.data, tempname);
        }).catch(err => {
          console.log(err);
        })
      },
      getPdfALLPAID() {
        const invoiceID = this.$route.params.id
        axios.get(`/api/v1/clients/${this.client.id}/generate_pdf_commission/${this.currency_selected}/FALSE`, {
          responseType: 'blob',
        }).then(res => {
          let tempname = 'invoice_'+ this.client.name  + this.currency_selected + '_' + new Date().toLocaleString() + '.pdf'
          fileDownload(res.data, tempname);
        }).catch(err => {
          console.log(err);
        })
      },      
      getPdfAll() {
        const invoiceID = this.$route.params.id
        axios.get(`/api/v1/clients/${this.client.id}/generate_pdf_commission/All`, {
          responseType: 'blob',
        }).then(res => {
          let tempname = 'invoice_ALL_' + this.client.name + new Date().toLocaleString() + '.pdf'
          fileDownload(res.data, tempname);
        }).catch(err => {
          console.log(err);
        })
      },
    },
  }
</script>