<template>
  <h3 class="is-size-4 mb-5">General Supplier Reports</h3>
  <div class="buttons">
    <button class="button is-primary" @click="getClientHighLow()">Supplier Rank Low to High</button>
    <button class="button is-primary" @click="getClientLowHigh()">Supplier Rank High to Low</button>
    <button class="button is-primary" @click="getClientHighLowCommission()">Commission High</button>
    <button class="button is-primary" @click="getClientLowHighCommission()">Commission Low</button>
    <button class="button is-primary" @click="getClientCountries()">List Country</button>
  </div>

  <div >
    <div class="box mt-4">
      <div class="column is-12">
        <h3 class="is-size-4 mb-5">Supplier Based Reports</h3>
        <div class="select">
          <select name="client" v-model="invoice.client">
            <option value="">- Select Supplier -</option>
            <option v-for="client in clients" v-bind:key="client.id" v-bind:value="client">
              {{ client.name }}
            </option>
          </select>
        </div>
        <div class="box mt-4" v-if="invoice.client">
          <p>
            <strong>{{ invoice.client.name }}</strong>
          </p>
          <p>
            <strong>Email:</strong> {{ invoice.client.email }}
          </p>
          <p v-if="invoice.client.address1">{{ invoice.client.address1 }}</p>
          <p v-if="invoice.client.address2">{{ invoice.client.address2 }}</p>
          <p v-if="invoice.client.zipcode || invoice.client.place">{{ invoice.client.zipcode }} {{ invoice.client.place }}</p>
          <p v-if="invoice.client.country">{{ invoice.client.country }}</p>
        </div>
      </div>
      <div class="column is-12">
        <h2 class="is-size-5 mb-4">Report Type</h2>
        <div class="column">
                <label>Select Year Preset All Currencies</label>
                <div class="buttons">
                  <button class="button is-primary" @click="getClientDatePresetReportMethod('all','all',new Date().getFullYear()-2  )">Commission all Report {{new Date().getFullYear()-2 }}</button>
                  <button class="button is-primary" @click="getClientDatePresetReportMethod('all','all',new Date().getFullYear()-1  )">Commission all Report {{new Date().getFullYear()-1 }}</button>
                  <button class="button is-primary" @click="getClientDatePresetReportMethod('all','all',new Date().getFullYear())">Commission all Report {{new Date().getFullYear()}}</button>
                  <div class="field column"></div>
                </div>

                <div class="buttons">
                  <button class="button is-primary" @click="getClientDatePresetReportMethod('all','PAID',new Date().getFullYear()-2  )">Commission PAID Report{{new Date().getFullYear()-2 }}</button>
                  <button class="button is-primary" @click="getClientDatePresetReportMethod('all','PAID',new Date().getFullYear()-1  )">Commission PAID Report {{new Date().getFullYear()-1 }}</button>
                  <button class="button is-primary" @click="getClientDatePresetReportMethod('all','PAID',new Date().getFullYear())">Commission PAID Report {{new Date().getFullYear()}}</button>
                  <div class="field column"></div>
                </div>
                <div class="buttons">
                  <button class="button is-primary" @click="getClientDatePresetReportMethod('all','UNPAID',new Date().getFullYear()-2  )">Commission UNPAID Report{{new Date().getFullYear()-2 }}</button>
                  <button class="button is-primary" @click="getClientDatePresetReportMethod('all','UNPAID',new Date().getFullYear()-1  )">Commission UNPAID Report {{new Date().getFullYear()-1 }}</button>
                  <button class="button is-primary" @click="getClientDatePresetReportMethod('all','UNPAID',new Date().getFullYear())">Commission UNPAID Report {{new Date().getFullYear()}}</button>
                  <div class="field column"></div>
                </div>
              </div>
        <div class="column is-12">

          <div class="" >
                    <h2 class="is-size-5 mb-4">DATE & CURRENCY </h2>

            <div class="columns">
              <div class="field column">
                <label>Start date</label>
                <div class="control">
                  <input type="date" class="input" v-model="date_start">
                </div>
              </div>
              <div class="field column">
                <label>End date</label>
                <div class="control">
                  <input type="date" class="input" v-model="date_end">
                </div>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <label>All Date with Currencies</label>
                <div class="buttons" >
                  <button class="button is-primary" @click="getClientDateReport('all')">Commission all Report</button>
                  <button class="button is-primary" @click="getClientDateReport('UNPAID')">Commission Unpaid Report</button>
                  <button class="button is-primary" @click="getClientDateReport('PAID')">Commission Paid Report</button>
                  <div class="field column"></div>
                </div>
              </div>

            </div>
            <label>Select Currencies</label>
            <div class="select">
              <select v-model="currency">
                <option>AUD</option>
                <option>EUR</option>
                <option>IDR</option>
                <option>USD</option>
              </select>
            </div>
            <div class="columns">
              <div class="column">
                <label>Select Date Preset Currency</label>
                <div class="buttons">
                  <button class="button is-primary" @click="getClientDateUnpaidReportMethod(currency,'all')">Commission all Report {{currency}}</button>
                  <button class="button is-primary" @click="getClientDateUnpaidReportMethod(currency,'UNPAID')">Commission Unpaid Report {{currency}}</button>
                  <button class="button is-primary" @click="getClientDateUnpaidReportMethod(currency,'PAID')">Commission Paid Report {{currency}}</button>
                  <div class="field column"></div>
                </div>
                <h2 class="is-size-5 mb-4">SELECT DATE AND CURRENCY </h2>

                <label>Select Year Preset Currency</label>
                <div class="buttons">
                  <button class="button is-primary" @click="getClientDatePresetReportMethod(currency,'all',new Date().getFullYear()-2  )">Commission all {{currency}} {{new Date().getFullYear()-2 }}</button>
                  <button class="button is-primary" @click="getClientDatePresetReportMethod(currency,'all',new Date().getFullYear()-1  )">Commission Unpaid Report {{currency}} {{new Date().getFullYear()-1 }}</button>
                  <button class="button is-primary" @click="getClientDatePresetReportMethod(currency,'all',new Date().getFullYear())">Commission Paid Report {{currency}} {{new Date().getFullYear()}}</button>
                  <div class="field column"></div>
                </div>
                                <div class="buttons">
                  <button class="button is-primary" @click="getClientDatePresetReportMethod(currency,'PAID',new Date().getFullYear()-2  )">Commission PAID Report {{currency}} {{new Date().getFullYear()-2 }}</button>
                  <button class="button is-primary" @click="getClientDatePresetReportMethod(currency,'PAID',new Date().getFullYear()-1  )">Commission PAID Report {{currency}} {{new Date().getFullYear()-1 }}</button>
                  <button class="button is-primary" @click="getClientDatePresetReportMethod(currency,'PAID',new Date().getFullYear())">Commission PAID Report {{currency}} {{new Date().getFullYear()}}</button>
                  <div class="field column"></div>
                </div>
                                <div class="buttons">
                  <button class="button is-primary" @click="getClientDatePresetReportMethod(currency,'UNPAID',new Date().getFullYear()-2  )">Commission UNPAID Report {{currency}} {{new Date().getFullYear()-2 }}</button>
                  <button class="button is-primary" @click="getClientDatePresetReportMethod(currency,'UNPAID',new Date().getFullYear()-1  )">Commission UNPAID Report {{currency}} {{new Date().getFullYear()-1 }}</button>
                  <button class="button is-primary" @click="getClientDatePresetReportMethod(currency,'UNPAID',new Date().getFullYear())">Commission UNPAID Report {{currency}} {{new Date().getFullYear()}}</button>
                  <div class="field column"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>
<script>
  import axios from 'axios'
  const fileDownload = require('js-file-download')
  import {
    toast
  } from 'bulma-toast'
  export default {
    name: 'ReportSupplier',
    props: {},
    data() {
      return {
        choices: "",
        supplierchoice: "General Supplier",
        date_or_button: "",
        currency: "AUD",
        date_start: "",
        date_end: "",
        clients: [],
        invoices: [],
        invoice: {
          client: '',
          buyer: '',
          items: [{
            item_num: 0,
            title: '',
            unit_price: '',
            quantity: 1,
            containers: 1,
            gst_rate: 0,
            net_amount: 0
          }],
          net_amount: 0,
          gst_amount: 0,
          Commission_owed: 0,
          gross_amount: 0,
          terms_and_conditions: ""
        },
      }
    },
    async mounted() {
      await this.getClients()
      await this.getInvoiceList()
    },
    methods: {
      getInvoiceList() {
        axios.get('/api/v1/invoices/').then(response => {
          this.invoices = response.data
        }).catch(error => {
          console.log(JSON.stringify(error))
        })
      },
      getClients() {
        axios.get('/api/v1/clients/').then(response => {
          this.clients = response.data
        }).catch(error => {
          console.log(JSON.stringify(error))
        })
      },
      getClientDateReport(paid) {
        const clientID = this.invoice.client.id
        console.log(clientID)
        axios.get(`/api/v1/clients/${clientID}/generate_client_all_invocies/${this.date_start}/${this.date_end}/${paid}`, {
          responseType: 'blob',
        }).then(res => {
          fileDownload(res.data, `REPORT_${clientID}:${this.invoice.client.name}[${this.date_start}-${this.date_end}]*${paid}.pdf`);
        }).catch(err => {
          console.log(err);
        })
      },
            getClientAllDateReport(paid) {
        const clientID = this.invoice.client.id
        console.log(clientID)
        axios.get(`/api/v1/clients/generate_client_all_invocies_all/${this.date_start}/${this.date_end}/${paid}`, {
          responseType: 'blob',
        }).then(res => {
          fileDownload(res.data, `REPORT_all${this.invoice.client.name}[${this.date_start}-${this.date_end}]*${paid}.pdf`);
        }).catch(err => {
          console.log(err);
        })
      },
      getClientDateReportGeneral(paid) {
        const clientID = this.invoice.client.id
        console.log(clientID)
        axios.get(`/api/v1/clients/all/generate_client_all_invocies/${this.date_start}/${this.date_end}/${paid}`, {
          responseType: 'blob',
        }).then(res => {
          fileDownload(res.data, `REPORT_all[${this.date_start}-${this.date_end}]*${paid}.pdf`);
        }).catch(err => {
          console.log(err);
        })
      },
      getClientDateUnpaidReportMethod(currency, paid) {
        const clientID = this.invoice.client.id
        console.log(clientID)
        axios.get(`/api/v1/clients/${clientID}/generate_client_currency_invocies/${this.date_start}/${this.date_end}/${paid}/${currency}`, {
          responseType: 'blob',
        }).then(res => {
          fileDownload(res.data, `REPORT_all${this.invoice.client.name}*${currency}[${this.date_start}-${this.date_end}]*${paid}.pdf`);
        }).catch(err => {
          console.log(err);
        })
      },
      getClientDatePresetReportMethod(currency, paid, date) {
        const clientID = this.invoice.client.id
        axios.get(`/api/v1/clients/${clientID}/generate_client_currency_invocies/${date}-1-1/${date}-12-31/${paid}/${currency}`, {
          responseType: 'blob',
        }).then(res => {
          fileDownload(res.data, `REPORT_all${this.invoice.client.name}[1-1-${date}/1-1-${date}]*${paid}.pdf`);
        }).catch(err => {
          console.log(err);
        })
      },
      getClientHighLow() {
        const clientID = this.invoice.client.id
        axios.get(`/api/v1/clients/highlowlist/True`, {
          responseType: 'blob',
        }).then(res => {
          fileDownload(res.data, `REPORT_HIGH_LOW.pdf`);
        }).catch(err => {
          console.log(err);
        })
      },
      getClientLowHigh() {
        const clientID = this.invoice.client.id
        axios.get(`/api/v1/clients/highlowlist/False`, {
          responseType: 'blob',
        }).then(res => {
          fileDownload(res.data, `REPORT_LOW_HIGH.pdf`);
        }).catch(err => {
          console.log(err);
        })
      },
      getClientLowHighCommission() {
        const clientID = this.invoice.client.id
        axios.get(`/api/v1/clients/highlowlistCommission/False`, {
          responseType: 'blob',
        }).then(res => {
          fileDownload(res.data, `REPORT_LOW_HIGH_COMMISSION.pdf`);
        }).catch(err => {
          console.log(err);
        })
      },
      getClientHighLowCommission() {
        const clientID = this.invoice.client.id
        axios.get(`/api/v1/clients/highlowlistCommission/True`, {
          responseType: 'blob',
        }).then(res => {
          fileDownload(res.data, `REPORT_HIGH_LOW_COMMISSION.pdf`);
        }).catch(err => {
          console.log(err);
        })
      },
      getClientCountries() {
        const clientID = this.invoice.client.id
        axios.get('api/v1/clients/generateClientListCountries', {
          responseType: 'blob',
        }).then(res => {
          fileDownload(res.data, `REPORT_LIST_COUNTRY.pdf`);
        }).catch(err => {
          console.log(err);
        })
      }
    }
  }
</script>