<template>
    <div class="page-invoice">
        <nav class="breadcrumb" aria-label="breadcrumbs">
            <ul>
                <li><router-link to="/dashboard">Dashboard</router-link></li>
                <li><router-link to="/dashboard/invoices">Invoices</router-link></li>
                <li class="is-active"><router-link :to="{ name: 'Invoice', params: { id: this.$route.params.id }}" aria-current="true">{{ invoice.invoice_number }}</router-link></li>
            </ul>
        </nav>

        <div class="columns is-multiline">
            <div class="column is-12">
                <h1 class="title">Invoice - {{ invoice.invoice_number }}</h1>

                <div class="buttons">
                    <button @click="getPdf('BUYER')" class="button is-dark">Download Buyer PDF</button>
                    <button @click="getPdf('SUPPLIER')" class="button is-dark">Download Supplier PDF</button>
 
                    <button @click="setAsCommissionPaid()" class="button is-success" v-if="!invoice.is_commission_paid">Commission Paid</button>
                    <button @click="sendReminder()" class="button is-info" v-if="!invoice.is_paid && !invoice.is_credit_for">Send reminder</button>
                </div>
            </div>

            <div class="column is-12 mb-4">
                <div class="box">
                    <h3 class="is-size-4 mb-5">Supplier</h3>

                    <p><strong>{{ invoice.client_name }}</strong></p>
                    
                    <p v-if="invoice.client_address1">{{ invoice.client_address1 }}</p>
                    <p v-if="invoice.client_address2">{{ invoice.client_address2 }}</p>
                    <p v-if="invoice.client_zipcode || invoice.client_place">{{ invoice.client_zipcode }} {{ invoice.client_place }}</p>
                    <p v-if="invoice.client_country">{{ invoice.client_country }}</p>
                </div>
            </div>
            <div class="column is-12 mb-4">
                <div class="box">
                    <h3 class="is-size-4 mb-5">Buyer</h3>

                    <p><strong>{{ invoice.buyer_name }}</strong></p>
                    
                    <p v-if="invoice.buyer_address1">{{ invoice.buyer_address1 }}</p>
                    <p v-if="invoice.buyer_address2">{{ invoice.buyer_address2 }}</p>
                    <p v-if="invoice.buyer_zipcode || invoice.buyer_place">{{ invoice.buyer_zipcode }} {{ invoice.buyer_place }}</p>
                    <p v-if="invoice.buyer_country">{{ invoice.buyer_country }}</p>
                </div>
            </div>

            <div class="column is-12 mb-4">
                <div class="box">
                    <h3 class="is-size-4 mb-5">Items</h3>

                    <table class="table is-fullwidth">
                        <thead>
                            <tr>
                                <th>Title</th>
                                <th>Unit Price</th>
                                <th>Commission</th>
                                <th>Quantity</th>
                                <th>Containers</th>
                                
                                <th>GST rate</th>
                                <th>Commission Total</th>
                                <th>Item Total</th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr 
                                v-for="item in invoice.items"
                                v-bind:key="item.id"
                            >
                                <td>{{ item.title }}</td> 
                                <td>{{ item.unit_price }}</td>
                                <td>{{ item.commission_item }}</td>
                                <td>{{ item.quantity }}</td>
                                <td>{{ item.containers }}</td>
                                <td>{{ item.gst_rate }}%</td>
                                <td>{{ item.commission_item_total}}%</td>
                                <td>{{ getItemTotal(item) }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div class="column is-12">
                <div class="box">
                    <h3 class="is-size-4 mb-5">Summary</h3>

                    <div class="columns">
                        <div class="column is-6">
                            <p><strong>Net amount</strong>: {{ invoice.net_amount }} {{invoice.currency_type}}</p>
                            <p><strong>GST amount</strong>: {{ invoice.gst_amount }} {{invoice.currency_type}}</p>
                            <p><strong>Gross amount</strong>: {{ invoice.gross_amount }} {{invoice.currency_type}}</p>
                            <br>
                            <p><strong>Commission amount</strong>: {{ invoice.commission_amount }} {{invoice.currency_type}}</p>
                            <br>
                            <p><strong>Gross amount + Commission amount</strong>: {{ parseFloat(invoice.gross_amount) + parseFloat(invoice.commission_amount) }} {{invoice.currency_type}}</p>
                        </div>
                    
                        <div class="column is-6">
                            <p><strong>Our reference</strong>: {{ invoice.id }}</p>
                            <p><strong>Contract reference</strong>: {{ invoice.sender_reference }}</p>
                            <p><strong>Due date</strong>: {{ invoice.get_due_date_formatted }}</p>
                            <p><strong>Status</strong>: {{ getStatusLabel() }}</p>
                            <p><strong>Invoice type</strong>: {{ getInvoiceType() }}</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="column is-12">
                <div class="box">
                    <h3 class="is-size-4 mb-5">Shipment Information</h3>

                    <div class="columns">
                        <div class="column is-6">
                            <p><strong>Insurance</strong>: {{ invoice.insurance }}</p>
                            <p><strong>Shipment Before</strong>: {{ invoice.get_shipment_before_date }}</p>
                            <p><strong>Port of Shipment</strong>: {{ invoice.port_of_shipment }}</p>
                            <p><strong>Destination</strong>: {{ invoice.destination }}</p>
                        </div>
                    
                        <div class="column is-6">
                            <p><strong>Shipping Mark</strong>: {{ invoice.shipping_mark }}</p>
                            <p><strong>Brand</strong>: {{ invoice.brand }}</p>
                            <p><strong>Shipment</strong>: {{ invoice.shipment }}</p>
                        </div>
                    </div>

                                        <div class="columns">
                        <div class="column is-6">
                            <p><strong>ETA Date</strong>: {{ invoice.get_eta_date }}</p>
                            <p><strong>Due Date</strong>: {{ invoice.get_due_date_formatted }}</p>
                            <p><strong>Payment Terms </strong>: {{ invoice.payment_terms     }}</p>

                            <p><strong>Terms </strong>: {{ invoice.terms_and_conditions }}</p>
                            <br>
                            <br>
                        </div>
                    
                        <div class="column is-6">
                            <br>
                            <p><strong>Notes</strong>: {{ invoice.notes }}</p>
                            <br>
                            <br>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios'
    import { toast } from 'bulma-toast'

    const fileDownload = require('js-file-download')

    export default {
        name: 'Invoice',
        data() {
            return {
                invoice: {},
                items: [],
                clientHolder:{},
            }
        },
        mounted() {
            this.getInvoice()
        },
        methods: {
            getInvoice() {
                const invoiceID = this.$route.params.id

                axios
                    .get(`/api/v1/invoices/${invoiceID}`)
                    .then(response => {
                        this.invoice = response.data

                    })
                    .catch(error => {
                        console.log(JSON.stringify(error))
                    })
            },
            getPdf(type) {
                const invoiceID = this.$route.params.id

                axios
                    .get(`/api/v1/invoices/${invoiceID}/generate_pdf/${type}`, {
                        responseType: 'blob',
                    }).then(res => {
                        fileDownload(res.data, `invoice_${invoiceID}.pdf`);
                    }).catch(err => {
                        console.log(err);
                    })
            },
            getStatusLabel() {
                if (this.invoice.is_paid) {
                    return 'Is paid'
                } else {
                    return 'Is not paid'
                }
            },
            getInvoiceType() {
                if (this.invoice.invoice_type === 'credit_note') {
                    return 'Credit note'
                } else {
                    return 'Invoice'
                }
            },
            getItemTotal(item) {
                const unit_price = item.unit_price
                const quantity = item.quantity
                const total = item.net_amount + (item.net_amount * (item.gst_rate / 100))

                return parseFloat(total).toFixed(2)
            },
            async setAsPaid() {
                this.invoice.is_paid = true

                let items = this.invoice.items

                

                await axios
                    .patch(`/api/v1/invoices/${this.invoice.id}/`, this.invoice)
                    .then(response => {
                        toast({
                            message: 'The changes was saved',
                            type: 'is-success',
                            dismissible: true,
                            pauseOnHover: true,
                            duration: 2000,
                            position: 'bottom-right',
                        })
                    })
                    .catch(error => {
                        console.log(JSON.stringify(error))
                    })
                
                this.invoice.items = items
                
            },
            async setAsCommissionPaid() {
                console.log(this.invoice)
                this.invoice.is_commission_paid = true
		this.invoice.is_paid = true
                let items = this.invoice.items

                delete this.invoice['items']
                

                await axios
                    .patch(`/api/v1/invoices/${this.invoice.id}/`, this.invoice)
                    .then(response => {
                        toast({
                            message: 'The changes was saved',
                            type: 'is-success',
                            dismissible: true,
                            pauseOnHover: true,
                            duration: 2000,
                            position: 'bottom-right',
                        })
                                              axios
                .get(`/api/v1/clients/${this.invoice.client}`)
                .then(response => {
                
                    this.clientHolder = response.data
                    console.log(this.clientHolder)
                    let temp = parseFloat(this.clientHolder.commission_owed)
                    temp += parseFloat(this.invoice.Commission_owed)
                   
                    this.clientHolder.commission_total = 0
                    this.clientHolder.commission_owed = 0
                    console.log(this.clientHolder.invoices.length)
                    for (let i = 0; i < this.clientHolder.invoices.length; i++) {
                        this.clientHolder.commission_total += parseFloat(this.clientHolder.invoices[i].commission_amount)
                        console.log(this.clientHolder)
                    }
                    for (let i = 0; i < this.clientHolder.invoices.length; i++) {
                        if (!this.clientHolder.invoices[i].is_commission_paid){
                        this.clientHolder.commission_owed += parseFloat(this.clientHolder.invoices[i].commission_amount)
                        console.log(this.clientHolder)
                        }
                    }

                switch(this.invoice.currency_type) {
                    case "AUD":
                        this.clientHolder.commission_aud = parseFloat(this.clientHolder.commission_aud) - parseFloat(this.invoice.commission_amount)
                        break;
                    case "EUR":
                        this.clientHolder.commission_eur = parseFloat(this.clientHolder.commission_eur) - parseFloat(this.invoice.commission_amount)
                        break;
                    case "IDR":
                        this.clientHolder.commission_idr = parseFloat(this.clientHolder.commission_idr) - parseFloat(this.invoice.commission_amount)
                        break;
                    case "USD":
                        this.clientHolder.commission_usd = parseFloat(this.clientHolder.commission_usd) - parseFloat(this.invoice.commission_amount)
                        break;
                }
                axios
                .patch(`/api/v1/clients/${this.invoice.client}/`, this.clientHolder)
                .then(response => {

                            this.$router.push('/dashboard/invoices')           
                    
                })
                .catch(error => {
                    console.log(JSON.stringify(error))
                })
                })
                .catch(error => {
                    console.log(JSON.stringify(error))
                })        


                    })
                    .catch(error => {
                        console.log(JSON.stringify(error))
                      
                        })
                    .catch(error => {
                        console.log(JSON.stringify(error))
                    })
                
                this.invoice.items = items
                this.setAsPaid()
            },
            async createCreditNote() {
                this.invoice.is_credited = true

                let items = this.invoice.items


                await axios
                    .patch(`/api/v1/invoices/${this.invoice.id}/`, this.invoice)
                    .then(response => {
                        toast({
                            message: 'The changes was saved',
                            type: 'is-success',
                            dismissible: true,
                            pauseOnHover: true,
                            duration: 2000,
                            position: 'bottom-right',
                        })
                    })
                    .catch(error => {
                        console.log(JSON.stringify(error))
                    })
                
                this.invoice.items = items
                
                let creditNote = this.invoice
                creditNote.is_credit_for = this.invoice.id
                creditNote.is_credited = false
                creditNote.invoice_type = 'credit_note'

                delete creditNote['id']

                await axios
                    .post('api/v1/invoices/', creditNote)
                    .then(response => {
                        toast({
                            message: 'The credit note was created',
                            type: 'is-success',
                            dismissible: true,
                            pauseOnHover: true,
                            duration: 2000,
                            position: 'bottom-right',
                        })

                        this.$router.push('/dashboard/invoices')
                    })
                    .catch(error => {
                        console.log(JSON.stringify(error))
                    })
            },
            sendReminder() {
                axios
                    .get(`/api/v1/invoices/${this.invoice.id}/send_reminder/`)
                    .then(response => {
                        toast({
                            message: 'The reminder was sent',
                            type: 'is-success',
                            dismissible: true,
                            pauseOnHover: true,
                            duration: 2000,
                            position: 'bottom-right',
                        })
                    })
                    .catch(error => {
                        console.log(JSON.stringify(error))
                    })
            }
        }
    }
</script>
