<template>
    <div class="columns">
        <div class="column is-2">
            <div class="field">
                <label>Product Name</label>
                <div class="control">
                    <input type="text" class="input" v-model="item.title" v-on:input="updatetest">
                </div>
            </div>
        </div>

        <div class="column is-2">
            <div class="field">
                <label>Unit Price</label>
                <div class="control">
                    <input type="number" class="input" v-model ="item.unit_price" v-on:input="updatetest">
                </div>
            </div>
        </div>

        <div class="column is-2">
            <div class="field">
                <label>Quantity</label>
                <div class="control">
                    <input type="number" class="input" v-model ="item.quantity" v-on:input="updatetest">
                </div>
            </div>
        </div>
        <div class="column is-1">
            <div class="field">
                <label>Containers</label>
                <div class="control">
                    <input type="number" class="input" v-model ="item.containers" v-on:input="updatetest">
                </div>
            </div>
        </div>
        <div class="column is-1">
            <div class="field">
                <label>Commission</label>
                <div class="control">
                    <input type="number" class="input" v-model ="item.commission_item" v-on:input="updatetest">
                </div>
            </div>
        </div>
        <div class="column is-1">
            <div class="field">
                <label>GST </label>
                <div class="control">
                    <div class="select">
                        <select v-model="item.gst_rate" v-on:change="updatetest">
                            <option value="0">0%</option>
                            <option value="14">14%</option>
                            <option value="25">25%</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>

        <div class="column is-1">
            <div class="field">
                <label>Gross </label>
                <div class="control">
                    <input type="text" class="input" v-bind:value="gross_amount" disabled>
                </div>
            </div>
        </div>
        <div class="column is-1">
            <div class="field">
                <label>Commission </label>
                <div class="control">
                    <input type="text" class="input" v-bind:value="commission" disabled>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ItemForm',
    props: {
        initialItem: Object
    },
    data() {
        return {
            item: this.initialItem
        }

    },
    methods:{
        updatePrice(item){
            this.$emit('updatePrice', item)
        },
        updatetest(){
            this.item.net_amount = parseFloat(this.item.net_amount).toFixed(2)
            this.updatePrice(this.item)        
        }
    },
    computed: {
        gross_amount() {
            const unit_price = this.item.unit_price
            const quantity = this.item.quantity
            const containers = this.item.containers
            const gst_rate = this.item.gst_rate
            this.item.net_amount = parseFloat(unit_price * quantity * containers ).toFixed(2)


            return parseFloat(this.item.net_amount + ((this.item.net_amount * (gst_rate / 100) )*containers )).toFixed(2)
        },
        commission() {

            const commission_item_var = this.item.commission_item

            this.item.commission_item_total = (commission_item_var * this.item.quantity * this.item.containers)

            //this.$emit('updatePrice', this.item)

            return parseFloat(this.item.commission_item_total).toFixed(2)
        }
    }
}
</script>