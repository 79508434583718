<template>
  <div class="home">
    <h1 class="title">Welcome to Contract Solution</h1>
  </div>
</template>

<script>


export default {
  name: 'Home',
  components: {
    
  }
}
</script>
